class TitleNames {
  title = '';
  setTitleName(name) {
    this.title = name;
  }

  getTitleName() {
    return this.title;
  }
}

export default TitleNames;